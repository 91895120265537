import { list, show, update, verify, countAutoselect, destroy } from '@/api/vacancies';

export const actions = {
  getList({ commit }, params) {
    var newParams = {...{}, ...params};
    if(newParams['category_id']){
      newParams['category_id'] = [newParams['category_id']]
    }
    return new Promise((resolve, reject) => {
      list(newParams)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_MODEL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  verify({ commit }, data) {
    return new Promise((resolve, reject) => {
      verify(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCountAutoselect({ commit }, vacancyId) {
    return new Promise((resolve, reject) => {
      countAutoselect(vacancyId)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_AUTO_SELECT_COUNT', {vacancy_id:vacancyId, res:res['data']});
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  destroy({ commit }, vacancyId) {
    return new Promise((resolve, reject) => {
      destroy(vacancyId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
