import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/moderator/resumes-list',
    method: 'get',
    params
  });
}
export function show(id) {
  return request({
    url: '/moderator/resumes/'+id,
    method: 'get'
  });
}
export function updateAvatar(data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/moderator/seeker-profile/'+data['user_id'],
    method: 'post',
    data: formData
  });
}
export function update(data) {
  return request({
    url: '/moderator/seeker-profile/'+data['user_id'],
    method: 'put',
    data
  });
}
export function deactivate(id) {
  return request({
    url: '/moderator/seeker-profile/'+id,
    method: 'delete'
  });
}
export function verified(id) {
  return request({
    url: '/moderator/seeker-profile/verify/'+id,
    method: 'post'
  });
}
// SeekerEducation
export function storeSeekerEducation(data) {
  return request({
    url: '/moderator/seeker-education/'+data['user_id'],
    method: 'post',
    data
  });
}
export function updateSeekerEducation(data) {
  return request({
    url: '/moderator/seeker-education/'+data['id'],
    method: 'put',
    data
  });
}
export function deleteSeekerEducation(id) {
  return request({
    url: '/moderator/seeker-education/'+id,
    method: 'delete'
  });
}
// SeekerExperience
export function storeSeekerExperience(data) {
  return request({
    url: '/moderator/seeker-experience/'+data['user_id'],
    method: 'post',
    data
  });
}
export function updateSeekerExperience(data) {
  return request({
    url: '/moderator/seeker-experience/'+data['id'],
    method: 'put',
    data
  });
}
export function deleteSeekerExperience(id) {
  return request({
    url: '/moderator/seeker-experience/'+id,
    method: 'delete'
  });
}
// SeekerCourse
export function storeSeekerCourse(data) {
  return request({
    url: '/moderator/seeker-course/'+data['user_id'],
    method: 'post',
    data
  });
}
export function updateSeekerCourse(data) {
  return request({
    url: '/moderator/seeker-course/'+data['id'],
    method: 'put',
    data
  });
}
export function deleteSeekerCourse(id) {
  return request({
    url: '/moderator/seeker-course/'+id,
    method: 'delete'
  });
}

export function checkOperator(data) {
  return request({
    url: '/operator/seeker-profile/'+data['user_id'],
    method: 'put',
    data
  });
}

export function notVerifiedProfiles(params) {
  return request({
    url: '/operator/not-verified-profiles',
    method: 'get',
    params
  });
}

export function resumePdf(id) {
  return request({
    url: '/pdf/' + id,
    method: 'get'
  });
}

export function toBasket(data) {
  return request({
    url: '/operator/seeker-profile/send-basket/' + data['id'],
    method: 'put',
    data
  });
}

export function uploadAvatarBase64(data) {
  return request({
    url: '/operator/avatar-upload',
    method: 'put',
    data
  });
}

export function getAvatarBase64(id) {
  return request({
    url: '/operator/seeker-profile/get-avatar/'+id,
    method: 'get',
  });
}

export function seekerProfilesDailyStatistics(params) {
  return request({
    url: '/moderator/seeker-profiles/daily-statistics',
    method: 'get',
    params
  });
}