import { classifiers, formOptions } from '@/api/classifiers';

export const actions = {
  getClassifiers({ commit }, data) {
    return new Promise((resolve, reject) => {
      classifiers(data['table'], data['params'])
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CLASSIFIERS', {table: data['table'], data: res['data']});
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getFormOptions({ commit }, params) {
    return new Promise((resolve, reject) => {
      formOptions(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_FORM_OPTIONS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
