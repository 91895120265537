export default {
  data() {
    var ruleLength = (rule, value, callback) => {
      if (String(value).length != rule.number) {
        callback(new Error(`Количество символов должно быть`, {param: rule.number}));
      } else {
        callback();
      }
    };
    var ruleMinLength = (rule, value, callback) => {
      if (String(value).length < rule.number) {
        callback(new Error(`Количество символов должно быть минимум ${rule.number}`));
      } else {
        callback();
      }
    };
    var ruleMaxLength = (rule, value, callback) => {
      if (String(value).length > rule.number) {
        callback(new Error(`Количество символов должно быть максимум`, {param: rule.number}));
      } else {
        callback();
      }
    };
    var compare = (rule, value, callback) => {
      if (value != rule.pass) {
        callback(new Error(`Пожалуйста, введите пароль еще раз`));
      } else {
        callback();
      }
    };
    var requiredIf = (rule, value, callback) => {
      if(!value && !rule.otherfield){
        callback(new Error(`Поля не может быть пустым`));
      } else {
        callback();
      }
    };
    var requiredIfEqual = (rule, value, callback) => {
      if(!rule.otherfield){
        callback(new Error(`Поля не может быть пустым`));
      } else if(rule.length < 150){
        callback(new Error(`Описание вакансии не может быть менее 150 символов`));
      } else  {
        callback();
      }
    };
    return {
      vRequiredIf: (otherfield) => {
        return { validator: requiredIf, otherfield: otherfield, trigger: 'blur' };
      },
      vRequiredIfEqual: (otherfield, length) => {
        return { validator: requiredIfEqual, otherfield: otherfield, length, trigger: 'blur' };
      },
      vRuleLength: (number, textNumber) => {
        return { validator: ruleLength, number: number, textNumber: (textNumber?textNumber:number), trigger: 'blur' };
      },
      vRuleMinLength: (number) => {
        return { validator: ruleMinLength, number: number, trigger: 'blur' };
      },
      vRuleMaxLength: (number) => {
        return { validator: ruleMaxLength, number: number, trigger: 'blur' };
      },
      vConfomPass: (pass) => {
        return { validator: compare, pass: pass, trigger: 'blur' };
      },
      vRequired: { required: true, message: 'Поля не может быть пустым' },
      vRequiredList: { required: true, message: 'Пожалуйста, выберите хотя бы одно значение' },
      vRequiredRadio: { required: true, message: 'Пожалуйста, выберите одно значение' },
      vEmail: { type: 'email', message: 'Введите в формате адрес@ящик.домен', trigger: ['blur', 'change'] },
      vNumeric: { type: 'number', message: 'Поле должно быть числовым' },
      vUrl: { type: 'url', message: '11111111' }
    };
  },
  methods: {
  },
};
