import request from '@/utils/request'

var role_path = '/moderator'

export function list(params, role='moderator') {
  return request({
    url: '/'+role+'/companies-list',
    method: 'get',
    params
  });
}
export function show(id, role='moderator') {
  return request({
    url: '/'+role+'/company/'+id,
    method: 'get',
  });
}
export function create(data, role='moderator') {
  const formData = new FormData();
  formData.append('_method', 'POST');
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(item => {
        formData.append(`${key}[]`, item);
      })
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/'+role+'/company/register',
    method: 'post',
    data: formData
  });
}
export function update(data, role='moderator') {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(item => {
        formData.append(`${key}[]`, item);
      })
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/'+role+'/company/'+data['id'],
    method: 'post',
    data: formData
  });
}
export function updateAsJson(data, role) {
  return request({
    url: '/'+role+'/company/'+data['id'],
    method: 'put',
    data
  });
}
export function deactivate(id, role='moderator') {
  return request({
    url: '/'+role+'/company/deactivate/'+id,
    method: 'put',
  });
}

export function companyVerify(data) {
  return request({
    url: '/moderator/company-verify/'+data.id,
    method: 'post',
    data
  });
}

export function setTrialCompanyUser(params, role='moderator') {
  return request({
    url: '/moderator/company-users/'+params.company_user_id,
    method: 'put',
    params
  });
}

export function companyRectuiters(id, role='moderator') {
  return request({
    url: '/moderator/company/recruiters/'+id,
    method: 'get',
  });
}

export function notVerifiedOtp(params, role='moderator') {
  return request({
    url: '/moderator/companies/not-verified-otp',
    method: 'get',
    params
  });
}

export function companyInvoices(id) {
  return request({
    url: '/seller/billing/invoices/'+id,
    method: 'get'
  });
}
export function companyContracts(id) {
  return request({
    url: '/seller/billing/contracts/'+id,
    method: 'get'
  });
}
export function downloadInvoicePdf(uuid) {
  return request({
    url: '/seller/billing/invoice-pdf/'+uuid,
    method: 'get'
  });
}
export function downloadContractPdf(id) {
  return request({
    url: '/seller/billing/contract-pdf/'+id,
    method: 'get'
  });
}
export function createCompanyContract(data) {
  return request({
    url: '/seller/billing/contract-create/'+data['company_id'],
    method: 'post',
    data
  });
}
export function createCompanyInvoice(data) {
  return request({
    url: '/seller/billing/invoice-create/'+data['company_id'],
    method: 'post',
    data
  });
}
export function createCompanyInvoiceRecruiters(data) {
  return request({
    url: '/seller/billing/invoice-create-for-recruiter/'+data['company_id'],
    method: 'post',
    data
  });
}
export function createCompanyInvoicePayme(data) {
  return request({
    url: '/seller/billing/invoice-create-for-payme/'+data['company_id'],
    method: 'post',
    data
  });
}
export function plans(params) {
  return request({
    url: '/billing/plans',
    method: 'get',
    params
  });
}
export function customPlans() {
  return request({
    url: '/billing/custom-plans',
    method: 'get'
  });
}
export function accounts(company_id) {
  return request({
    url: '/seller/company-accounts/'+company_id,
    method: 'get'
  });
}
export function createWorker(data) {
  return request({
    url: '/company/create-worker',
    method: 'post',
    data
  });
}
export function invoiceCreateForResumes(data) {
  return request({
    url: '/seller/billing/invoice-create-for-resumes',
    method: 'post',
    data
  });
}
export function invoiceCreateForVacancies(data) {
  return request({
    url: '/seller/billing/invoice-create-for-vacancies',
    method: 'post',
    data
  });
}