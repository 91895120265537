import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/moderator/position-synonym',
    method: 'get',
    params
  });
}
export function show(id) {
  return request({
    url: '/moderator/position-synonym/'+id,
    method: 'get',
  });
}
export function store(data) {
    return request({
      url: '/moderator/position-synonym',
      method: 'post',
      data
    });
  }
export function update(data) {
  return request({
    url: '/moderator/position-synonym/'+data['id'],
    method: 'put',
    data
  });
}