import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth';
import loader from './loader';
import classifiers from './classifiers';
import app from './app';
import statistics from './statistics'
import companies from './companies'
import vacancies from './vacancies'
import profiles from './profiles'
import responses from './responses'
import positions from './positions'
import position_synonym from './position-synonym'
import utmSourses from './utmSourses'
import candidates from './candidates'
import otms from './otms'
import company_requests from './company-requests'
import trainingCenters from './trainingCenters'

const store = new Vuex.Store({
  modules: {
    auth,
    loader,
    classifiers,
    app,
    statistics,
    companies,
    vacancies,
    profiles,
    responses,
    positions,
    position_synonym,
    utmSourses,
    candidates,
    otms,
    company_requests,
    trainingCenters
  }
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  let storeData = JSON.parse(JSON.stringify(store.state));
  initialStateCopy['auth']['is_auth'] = false;
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}