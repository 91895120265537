<template>
  <span v-if="text">{{text}}</span>
</template>

<script>
export default {
  props: {
    id: {
      type: Number|String,
      default:() => 0
    },
    items: {
      type: Array,
      default:() => []
    }
  },
  data() {    
    return {
    }
  },
  computed:{
    text(){
      if(this.items && this.items.length){
        let find = this.items.find(item => item.id == Number(this.id));
        return find?find.name_ru:'';
      }
      return "";
    }
  },
}
</script>