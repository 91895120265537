import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/moderator/vacancies-list',
    method: 'get',
    params
  });
}
export function show(id) {
  return request({
    url: '/moderator/vacancies/'+id,
    method: 'get',
  });
}
export function update(data) {
  return request({
    url: '/moderator/vacancies/'+data['id'],
    method: 'put',
    data
  });
}
export function verify(data) {
  return request({
    url: '/moderator/vacancies/verify/'+data['id'],
    method: 'post',
    data
  });
}
export function countAutoselect(vacancy_id) {
  return request({
    url: '/autoselection/resumes/count/'+vacancy_id,
    method: 'get',
  });
}
export function destroy(id) {
  return request({
    url: '/moderator/vacancies/send-archive/' + id,
    method: 'put'
  });
}