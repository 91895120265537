export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data['data'].map(item => {
      item['autoselect_count'] = "";
      return item;
    });
    state.pagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
  SET_MODEL: (state, data) => {
    state.model = data;
  },
  SET_AUTO_SELECT_COUNT: (state, data) => {
    let vacancyId = data['vacancy_id'];
    console.log(data['res']);
    let count = data['res'];
    if(state.list && state.list.length) {
      state.list = state.list.map(item => {
        if(item.id == vacancyId) item.autoselect_count = count;
        return item;
      })
    }
  },
};