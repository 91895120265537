const routes = [
  {
    path: 'profiles/edit/:id',
    name: 'ProfilesEdit',
    meta: {middleware: 'Auth'},
    component: () =>
        import("@/views/Profiles/Edit"),
  },{
    path: 'profiles/:id',
    name: 'ProfilesShow',
    meta: {middleware: 'Auth'},
    component: () =>
        import("@/views/Profiles/Show"),
  },{
    path: 'profiles-for-check',
    name: 'ProfilesShowForCheck',
    meta: {middleware: 'AuthOperator'},
    component: () =>
        import("@/views/Profiles/ShowForCheck"),
  }
]

export default routes;
