import { 
  list, 
  show, 
  store, 
  update, 
  parents,
  addSkill,
  removeSkill,
  addNewSkill,
  skillsList
} from '@/api/positions';

export const actions = {
  getList({ commit }, params) {
    var newParams = {...{}, ...params};
    if(newParams['category_id']){
      newParams['category_id'] = [newParams['category_id']]
    }
    return new Promise((resolve, reject) => {
      list(newParams)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_MODEL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getParents({ commit }) {
    return new Promise((resolve, reject) => {
      parents()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_PARENTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addSkill({ commit },data) {
    return new Promise((resolve, reject) => {
      addSkill(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeSkill({ commit },data) {
    return new Promise((resolve, reject) => {
      removeSkill(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addNewSkill({ commit },data) {
    return new Promise((resolve, reject) => {
      addNewSkill(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  skillsList({ commit }, params) {
    return new Promise((resolve, reject) => {
      skillsList(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_SKILLS_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
