import request from '@/utils/request';

export function statBySteps(params) {
  return request({
    url: '/moderator/candidate-by-steps',
    method: 'get',
    params
  });
}
export function verifiedProfilesCounts() {
  return request({
    url: '/operator/verified-profiles-statistic',
    method: 'get'
  });
}