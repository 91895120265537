<template>
  <span>{{text}}</span>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    id: {
      type: Number | String,
      required: true
    },
    objectkey: {
      type: String,
      required: true
    }
  },
  data() {    
    return {
    }
  },
  computed:{
    ...mapGetters({
      formOptions: 'classifiers/FORM_OPTIONS',
    }),
    text(){
      if(this.formOptions[this.objectkey]){
        let find = this.formOptions[this.objectkey].find(item => item.id == Number(this.id));
        return find?find.name_ru:'';
      }
      return '';
    }
  },
  created() {
  },
  methods: {
  }
}
</script>