import { 
  list, 
  show, 
  store, 
  update, 
  remove
} from '@/api/otms';

export const actions = {
  getList({ commit }, params) {
    var newParams = {...{}, ...params};
    return new Promise((resolve, reject) => {
      list(newParams)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_MODEL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  remove({ commit },data) {
    return new Promise((resolve, reject) => {
      remove(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
