export const getters = {
  TABLES: (state) => state.tables,
  WORKER_COUNTS: (state) => state.workerCounts,
  FORM_OPTIONS: (state) => state.formOptions,
  DRIVERS_LICENSES: (state) => state.driversLicenses,
  CURRENCIES: (state) => state.currencies,
  GET_CATEGORIES: (state) => state.categories,
  FLAT_CATEGORIES: (state) => state.flatCategories,
  ERRORS: (state) => state.errors,
  UZB_REGIONS: (state) => state.uzb_regions,
}
