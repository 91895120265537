import { Notification } from 'element-ui';

export function errorsAlert(error) {
  let requestBody = error.config.data?JSON.parse(error.config.data):null;
  let requestMethodName = requestBody && requestBody['method']?requestBody['method']:"";
  let dataError = error.data.error?error.data.error:null;
  if(dataError && dataError.data && dataError.data.message){
    let validateErrors = getValidateErrors(error);
    if(error.status == 422 && validateErrors){
      Notification.error({
        title: 'Внимание',
        dangerouslyUseHTMLString: true,
        message: `<b>${requestMethodName}</b><br/> ${validateErrors}`,
        duration: 0
      });
    }else{
      Notification.error({
        title: 'Внимание',
        dangerouslyUseHTMLString: true,
        message: `<b>${requestMethodName}</b><br/> ${dataError.data.message} |${dataError.code}`,
        duration: 0
      });
    }
  } else if (dataError && dataError.message) {
    Notification.error({
      title: 'Внимание',
      dangerouslyUseHTMLString: true,
      message: `<b>${requestMethodName}</b><br/> ${dataError.message} |${dataError.code}`,
      duration: 0
    });
  } else if (error.status && error.statusText){
    Notification.error({
      title: 'Внимание',
      dangerouslyUseHTMLString: true,
      message: `<b>${requestMethodName}</b><br/> ${error.statusText} |${error.status}`,
      duration: 0
    });
  } else if (error.data && error.data.message) {
    Notification.error({
      title: 'Внимание',
      dangerouslyUseHTMLString: true,
      message: `<br/> ${error.data.message} |${error.status}`,
      duration: 0
    });
  }
}

function getValidateErrors(error) {
  let status = error.status;
  if(error.data && error.data.error){
    error = error.data.error;
  }
  if (status == 422 && error.data && error.data.errors) {
    let message = "<br/> - ";
    Object.keys(error.data.errors).forEach(filed => {
      message += error.data.errors[filed].join(' <br/> - ');
    });
    return message;
  }
  return null;
}

export function netwokErrorAlert(error) {
  if(error && error.message == 'Network Error'){
    Notification.error({
      title: 'Внимание',
      dangerouslyUseHTMLString: true,
      message: `Нет соединения с сервером, пожалуйста проверьте подключение к интернету`,
      duration: 0,
    });
  }
}