const routes = [
  {
    path: 'vacancies/edit/:id',
    name: 'VacanciesEdit',
    meta: {middleware: 'Auth'},
    component: () =>
        import("@/views/Vacancies/Edit"),
  },{
    path: 'vacancies/:id',
    name: 'VacanciesShow',
    meta: {middleware: 'Auth'},
    component: () =>
        import("@/views/Vacancies/Show"),
  }
]

export default routes;
