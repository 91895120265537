export const getters = {
  LIST: state => state.list,
  PAGINATION: state => state.pagination,
  MODEL: state => state.model,
  RECRUITERS: state => state.recruiters,
  NOT_VERIFIED_OTPS: state => state.not_verified_otps,
  INVOICES: state => state.invoices,
  CONTRACTS: state => state.contracts,
  PLANS: state => state.plans,
  ACCOUNTS: state => state.accounts,
  CUSTOM_PLANS: state => state.customPlans,
}
