import request from '@/utils/request'

export function list(params) {
  return request({
    url: '/company-requests',
    method: 'get',
    params
  });
}

export function show(id) {
  return request({
    url: '/company-requests/'+id,
    method: 'get',
  });
}

export function update(data) {
  return request({
    url: '/company-requests/'+data['id'],
    method: 'put',
    data,
  });
}

export function newCount() {
  return request({
    url: '/company-requests/count-new-requests',
    method: 'get',
  });
}