import store from '@/store';
import moment from 'moment';
import 'moment-precise-range-plugin';
import {getNSTextRu} from '@/utils/index';
export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data['data'];
    state.pagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
  SET_MODEL: (state, data) => {
    state.model = setWorkerCounts(data)
  },
  SET_RECRUITERS: (state, data) => {
    state.recruiters = data
  },
  SET_NOT_VERIFIED_OTPS: (state, data) => {
    state.not_verified_otps = data['data'].map(item => {
      return {
        id: item.id,
        username: item.username,
        name: item.details ? (item.details['name'] ?? null) : null,
        contact_person: item.details ? (item.details['contact_person'] ?? null) : null,
        contact_number: item.details ? (item.details['contact_number'] ?? null) : null,
        worker_count: item.details ? (item.details['worker_count'] ?? null) : null,
        code: item.code,
        created_at: item.created_at
      }
    });
    state.pagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
  SET_INVOICES: (state, data) => {
    state.invoices = data
  },
  SET_CONTRACTS: (state, data) => {
    state.contracts = data
  },
  SET_PLANS: (state, data) => {
    state.plans = data
  },
  SET_CUSTOM_PLANS: (state, data) => {
    state.customPlans = data
  },
  SET_ACCOUNTS: (state, data) => {
    state.accounts = data.filter(item => item.role != 'company').map(item => {
      item['access_perion'] = getAccessPeriodDays(item.end_date)
      return item;
    });
  },
};

function setWorkerCounts(data){
  if(data && data.worker_count){
    let workerCounts = store.getters['classifiers/WORKER_COUNTS']
    let find = workerCounts.find(item => data.worker_count == item.id);
    if(find){
      data['workerCount'] = find
    }
  }
  return data;
}

function getAccessPeriodDays(endDate){
  let currentDate = moment().format('YYYY-MM-DD');
  if(currentDate == endDate){
    return ("Сегодня последний день доступа");
  }
  let text = "Осталось";
  let diffObj = moment(currentDate).preciseDiff(endDate, true);
  if(diffObj['years'] > 0){
    diffObj['months'] += (12*diffObj['years'])
  }
  if(diffObj['months'] > 0){
    text = `${text} ${diffObj['months']} ${getNSTextRu(diffObj['months'], 'месяц', 'месяца', 'месяцев')}`
  }
  if(diffObj['days'] > 0){
    if(diffObj['months'] > 0){
      text = `${text} и`;
    }
    text = `${text} ${diffObj['days']} ${getNSTextRu(diffObj['days'], 'день', 'дня', 'дней')}`
  }
  if(diffObj['years'] > 0 || diffObj['months'] > 0 || diffObj['days'] > 0){
    return `${text}`+" доступа"
  }
  return null;
}