import request from '@/utils/request';

export function statistics() {
  return request({
    url: '/moderator/get-statistics',
    method: 'get',
  });
}
export function resStepsCounts() {
  return request({
    url: '/moderator/get-on-step-resumes',
    method: 'get',
  });
}
export function profileStatistics() {
  return request({
    url: '/moderator/get-seeker-profile-statistics',
    method: 'get',
  });
}
export function countPositionResumes() {
  return request({
    url: '/moderator/count-position-resumes',
    method: 'get',
  });
}
export function regionsStatistics() {
  return request({
    url: '/regions-with-resumes',
    method: 'get',
  });
}
export function companiesVisitsStatistics() {
  return request({
    url: '/moderator/companies-visits-statistics',
    method: 'get',
  });
}

export function seekerProfilesDailyStatistics(params) {
  return request({
    url: '/moderator/seeker-profiles/daily-statistics',
    method: 'get',
    params
  });
}

export function companiesDailyStatistics(params) {
  return request({
    url: '/moderator/companies/daily-statistics',
    method: 'get',
    params
  });
}