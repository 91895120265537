import { orderBy } from "@/utils";

export const mutations = {
  SET_STAT: (state, data) => {
    state.statistics = data;
  },
  SET_PROFILE_STATISTICS: (state, data) => {
    data.education_levels = orderBy(data.education_levels.map((item, index) => {
      switch (item.education_level_id) {
        case 7:
          item['order'] = 1
          break;
        default:
          item['order'] = item.education_level_id
          break;
      }
      return item;
    }), 'order')
    state.profile_statistics = data;
  },
  SET_REGION_STATISTICS: (state, data) => {
    state.region_statistics = data;
  },
  SET_POSITION_STATISTICS: (state, data) => {
    state.position_statistics = data;
  },
  SET_REG_STEPS_COUNTS: (state, data) => {
    if(data && data.length > 0) {
      let total = 0;
      state.regStepsCounts = orderBy(data.map(item => {
        item['name'] = regStepsNames[item['registration_step']];
        total += item['count'];
        return item;
      }), 'registration_step');
      state.regStepsCounts.unshift({registration_step: "total", count: total});
    }
  },
};
const regStepsNames = {
  1: "Выбор региона",
  2: "Выбор должности",
  3: "Вид занятости",
  4: "Уровень образования",
  5: "Опыт работы",
  6: "Навыки",
  7: "Языки",
  8: "Категория прав",
  9: "Знание компьютера",
  10: "Сертификаты",
  11: "О себе",
  12: "Фото профиля",
  13: "Желаемая зарплата",
}
