import { 
  list, 
  show, 
  store, 
  update, 
  remove
} from '@/api/trainingCenters';

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {return show(id)},
  update({ commit }, data) {return update(data)},
  store({ commit }, data) {return store(data)},
  remove({ commit },id) {return remove(id)},
};
