const routes = [
    {
      path: 'positions/edit/:id',
      name: 'PositionsEdit',
      meta: {middleware: 'Auth'},
      component: () =>
          import("@/views/Positions/Edit"),
    },
    {
        path: 'positions/create',
        name: 'PositionsCreate',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/Positions/Create"),
      },{
      path: 'positions/:id',
      name: 'PositionsShow',
      meta: {middleware: 'Auth'},
      component: () =>
          import("@/views/Positions/Show"),
    }
  ]
  
  export default routes;
  