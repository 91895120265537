import { 
  statistics, 
  resStepsCounts, 
  profileStatistics, 
  regionsStatistics, 
  countPositionResumes,
  companiesVisitsStatistics,
  seekerProfilesDailyStatistics,
  companiesDailyStatistics
} from '@/api/statistics';

export const actions = {
  getStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      statistics()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_STAT', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getResStepsCounts({ commit }) {
    return new Promise((resolve, reject) => {
      resStepsCounts()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_REG_STEPS_COUNTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getProfileStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      profileStatistics()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_PROFILE_STATISTICS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRegionsStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      regionsStatistics()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_REGION_STATISTICS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCountPositionResumes({ commit }) {
    return new Promise((resolve, reject) => {
      countPositionResumes()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_POSITION_STATISTICS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCompaniesVisitsStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      companiesVisitsStatistics()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  seekerProfilesDailyStatistics({ commit }, data) {
    return new Promise((resolve, reject) => {
      seekerProfilesDailyStatistics(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  companiesDailyStatistics({ commit }, data) {
    return new Promise((resolve, reject) => {
      companiesDailyStatistics(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
