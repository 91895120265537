export const mutations = {
  SET_CLASSIFIERS: (state, res) => {
    const table = res['table'];
    const data = res['data'];
    const params = res['params'];
    switch (table) {
      case 'errors':
        state.errors = data;    
        break;
      case 'regions':
        if(!params) state.uzb_regions = data;
        state.tables[table] = data;    
        break;
      case 'categories':
        state.flatCategories = getFlatItems(data);
        state.tables[table] = data;    
        break;
      default:
        state.tables[table] = data;    
        break;
    }
  },
  SET_FORM_OPTIONS: (state, data) => {
    if(data){
      state.formOptions = {...state.formOptions, ...data};
    }
  }
};

function getFlatItems(items){
  var arr = [];
  if(items && items.length){
    items.forEach(function(item){
      arr.push(item);
      if(item.childs && item.childs.length){
        arr = arr.concat(getFlatItems(item.childs));
      }
    })
  }
  return arr;
}