import Cookies from 'js-cookie'
import { removeItem } from '@/utils/storage'
import { resetState } from '@/store/index'

export function getToken() {
  return Cookies.get('access_token')
}

export function setToken(token) {
  return Cookies.set('access_token', token)
}

export function removeToken() {
  return Cookies.remove('access_token');
}

export function clearForLogout() {
  Cookies.remove('access_token');
  Cookies.remove('access_token_time');
  removeItem('user');
  resetState();
  return true;
}

export function setTokenTime(expires_in) {
  var d = new Date();
  d.setHours(d.getHours() + (expires_in / 60));
  Cookies.set('access_token_time', d.getTime());
}

export function checkTokenTime() {
  let tokenTime = Cookies.get('access_token_time');
  return tokenTime > (new Date()).getTime();
}