import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/training-centers',
    method: 'get',
    params
  });
}
export function show(id) {
  return request({
    url: '/training-centers/'+id,
    method: 'get',
  });
}
export function store(data) {
  return request({
    url: '/training-centers',
    method: 'post',
    data
  });
}
export function update(data) {
  return request({
    url: '/training-centers/'+data['id'],
    method: 'put',
    data
  });
}
export function remove(id) {
  return request({
    url: '/training-centers/' + id,
    method: 'delete'
  });
}
