import { 
  statBySteps,
  verifiedProfilesCounts
} from '@/api/candidates';

export const actions = {
  getStatBySteps({ commit }, params) {
    return new Promise((resolve, reject) => {
      statBySteps(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_STAT_BY_STEPS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  verifiedProfilesCounts({ commit }) {
    return new Promise((resolve, reject) => {
      verifiedProfilesCounts()
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
