const routes = [
    {
      path: 'position-synonym/edit/:id',
      name: 'PositionSynonymEdit',
      meta: {middleware: 'Auth'},
      component: () =>
          import("@/views/PositionSynonym/Edit"),
    },
    {
        path: 'position-synonym/create',
        name: 'PositionSynonymCreate',
        meta: {middleware: 'Auth'},
        component: () =>
            import("@/views/PositionSynonym/Create"),
      },{
      path: 'position-synonym/:id',
      name: 'PositionSynonymShow',
      meta: {middleware: 'Auth'},
      component: () =>
          import("@/views/PositionSynonym/Show"),
    }
  ]
  
  export default routes;
  