import { 
  list, 
  show, 
  create, 
  update, 
  updateAsJson, 
  deactivate, 
  companyVerify, 
  setTrialCompanyUser, 
  companyRectuiters, 
  notVerifiedOtp,
  companyInvoices,
  companyContracts,
  downloadInvoicePdf,
  downloadContractPdf,
  createCompanyContract,
  createCompanyInvoice,
  createCompanyInvoicePayme,
  plans,
  createCompanyInvoiceRecruiters,
  accounts,
  customPlans,
  invoiceCreateForResumes,
  invoiceCreateForVacancies
} from '@/api/companies';
import store from '@/store';

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      list(params, store.getters['auth/USER'].role)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id, store.getters['auth/USER'].role)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_MODEL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data, store.getters['auth/USER'].role)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAsJson({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateAsJson(data, store.getters['auth/USER'].role)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  register({ commit }, data) {
    return new Promise((resolve, reject) => {
      create(data, store.getters['auth/USER'].role)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  companyVerify({ commit }, data) {
    return new Promise((resolve, reject) => {
      companyVerify(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setTrialCompanyUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      setTrialCompanyUser(id, store.getters['auth/USER'].role)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  companyRectuiters({ commit }, id) {
    return new Promise((resolve, reject) => {
      companyRectuiters(id, store.getters['auth/USER'].role)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_RECRUITERS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deactivate({ commit }, id) {
    return new Promise((resolve, reject) => {
      deactivate(id, store.getters['auth/USER'].role)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNotVerifiedOtp({ commit }, params) {
    return new Promise((resolve, reject) => {
      notVerifiedOtp(params, store.getters['auth/USER'].role)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_NOT_VERIFIED_OTPS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCompanyInvoices({ commit }, id) {
    return new Promise((resolve, reject) => {
      companyInvoices(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_INVOICES', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCompanyContracts({ commit }, id) {
    return new Promise((resolve, reject) => {
      companyContracts(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CONTRACTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  downloadInvoicePdf({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      downloadInvoicePdf(uuid)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  downloadContractPdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      downloadContractPdf(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCompanyContract({ commit }, data) {
    return new Promise((resolve, reject) => {
      createCompanyContract(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCompanyInvoice({ commit }, data) {
    return new Promise((resolve, reject) => {
      createCompanyInvoice(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCompanyInvoicePayme({ commit }, data) {
    return new Promise((resolve, reject) => {
      createCompanyInvoicePayme(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getPlans({ commit }, data) {
    return new Promise((resolve, reject) => {
      plans(data)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_PLANS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCompanyInvoiceRecruiters({ commit }, data) {
    return new Promise((resolve, reject) => {
      createCompanyInvoiceRecruiters(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAccounts({ commit }, company_id) {
    return new Promise((resolve, reject) => {
      accounts(company_id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_ACCOUNTS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getCustomPlans({ commit }) {
    return new Promise((resolve, reject) => {
      customPlans()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_CUSTOM_PLANS', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  invoiceCreateForResumes({ commit },data) {
    return invoiceCreateForResumes(data);
  },
  invoiceCreateForVacancies({ commit },data) {
    return invoiceCreateForVacancies(data);
  },
};
