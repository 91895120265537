const routes = [
  {
    path: 'profiles-chart',
    name: 'ProfilesChart',
    meta: {middleware: 'Auth'},
    component: () =>
        import("@/views/Charts/ProfilesDynamicsChart"),
  },
  {
    path: 'companies-chart',
    name: 'CompaniesChart',
    meta: {middleware: 'Auth'},
    component: () =>
        import("@/views/Charts/CompaniesDynamicsChart"),
  }
]

export default routes;
