<template>
  <transition name="loader">
    <div class="preloader" v-if="requestsRunning">
      <div class="preloader-content">
        <!-- <img src="/img/logo.svg" alt=""> -->
        <div class="loader loader--fade">
          <span class="loader-item">1</span>
          <span class="loader-item">2</span>
          <span class="loader-item">3</span>
          <span class="loader-item">4</span>
        </div>
        <!-- <div class="logo-loader-text">Подождите, идёт загрузка...</div> -->
      </div>
    </div>
  </transition>
</template>

<script>
	import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
      }
    },
    computed: {
	    ...mapGetters({ requestsRunning: 'loader/REQUEST_RUNNING' }),
	  },
  }
</script>

<style scope lang="scss">
.preloader{
  background: linear-gradient(0deg, #F2F4F6, #F2F4F6), linear-gradient(0deg, #F9F9F9, #F9F9F9), #FFFFFF;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-loader-text{
  margin-top: 30px;
}

$itemSize : 15;
$itemSpacing : 5;
$squareSize : 40;
$loaderColor : #606266;
$loaderDelay : 100;
$loaderDuration : 4000;
$animationTiming: cubic-bezier(.645, .045, .355, 1);

.loader {
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
  font-size: 0;
}
.loader--3d {
  transform-style: preserve-3d;
  perspective: 800px;
}
.loader--slideBoth {
  overflow: visible;
}

.loader-item {
  display: inline-block;
  width: $itemSize + px;
  height: $itemSize + px;
  margin-left: ($itemSpacing/2) + px;
  margin-right: ($itemSpacing/2) + px;
  background-color: $loaderColor;
  color: $loaderColor;
  
  
  animation-duration: $loaderDuration + ms;
  animation-timing-function: $animationTiming;
  animation-iteration-count: infinite;
  
  
  //loop through to add an animation delay
  @for $i from 1 through 8 {
    &:nth-child(#{$i}) {
      animation-delay: ($i * $loaderDelay) + ms;
    }
  }

  //add slowFlip to the first one
  .loader--slowFlip & {
    animation-name: slowFlip;
  }
  .loader--flipHoz & {
    animation-name: flipHoz;
  }
  .loader--fade & {
    animation-name: fade;
    animation-duration: 1000ms;
  }
  .loader--slowFlip &,
  .loader--flipHoz & {
    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        animation-delay: ($i * $loaderDelay*1.5) + ms;
      }
    }
  }
  

  .loader--flipDelay & {
    animation-name: flipDelay;
  }
  .loader--flipDelayDown & {
    animation-name: flipDelay;
    animation-direction: reverse;
  }

  .loader--slideDown &,
  .loader--slideUp & {
    animation-name: slideDown;
    animation-duration: ($loaderDuration/2.5) + ms;
    animation-timing-function: linear;
  }

 .loader--slideDown & {
    transform-origin: top left;
 }
 .loader--slideUp & {
    transform-origin: bottom left;
 }
 .loader--slideBoth & {
    animation-name: slideBoth;
   animation-duration: ($loaderDuration/2) + ms;
    transform-origin: bottom left;
   animation-timing-function: linear;
 }
}


/**********************************/
/* KEYFRAME ANIMATION DEFINITIONS */
/**********************************/

@keyframes slowFlip {
  0%    { transform: rotateX(0deg);   }
  40%   { transform: rotateX(180deg); }
  100%  { transform: rotateX(180deg); }
}


@keyframes flipHoz {
  0%    {  transform: rotateY(0deg);   }
  40%   { transform: rotateY(180deg);  }
  100%  { transform: rotateY(180deg);  }
}

@keyframes fade {
  0%    { opacity: 0;  }
  50%   { opacity: 1;  }
  100%  { opacity: 0;  }
}


@keyframes flipDelay {
  0% {
    transform: rotateX(0deg);
    transform-origin: 0 0 0;
    opacity: 1;
  }
  30% {
    transform: rotateX(90deg);
    transform-origin: 0 0 0;
    opacity: 0;
  }
  40% {
    transform-origin: 0 0 0;
  }
  60% {
    transform: rotateX(90deg);
    opacity: 0;
    transform-origin: 0 100% 0;
  }
  90% {
    transform: rotateX(0deg);
    opacity: 1;
    transform-origin: 0 100% 0;
  }
}

@keyframes slideDown {
  0%  { transform: rotateX(0deg);  }
  50% { transform: rotateX(90deg); }
}
@keyframes slideBoth {
  0%    { transform: rotateX(0deg);   }
  100%  { transform: rotateX(360deg); }
} 
</style>