import request from '@/utils/request';

export function stat(params) {
  return request({
    url: '/utm-source-user',
    method: 'get',
    params
  });
}
export function company(params) {
  return request({
    url: '/utm-source-company',
    method: 'get',
    params
  });
}
export function list(params) {
  return request({
    url: '/moderator/utm-source',
    method: 'get',
    params
  });
}
export function create(data) {
  return request({
    url: '/moderator/utm-source',
    method: 'post',
    data
  });
}

export function statistic(params) {
  return request({
    url: '/moderator/utm-sources-statistic/' + params['id'],
    method: 'get',
    params
  });
}