import { 
  list, 
  show, 
  update,
  updateAvatar,
  deactivate,
  verified,

  checkOperator,
  notVerifiedProfiles,
  
  storeSeekerEducation,
  updateSeekerEducation,
  deleteSeekerEducation,

  storeSeekerExperience,
  updateSeekerExperience,
  deleteSeekerExperience,

  storeSeekerCourse,
  updateSeekerCourse,
  deleteSeekerCourse,
  resumePdf,

  toBasket,
  uploadAvatarBase64,
  getAvatarBase64,

  seekerProfilesDailyStatistics
} from '@/api/profiles';

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      list(params)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_LIST', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_MODEL', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateAvatar({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateAvatar(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deactivate({ commit }, id) {
    return new Promise((resolve, reject) => {
      deactivate(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  verified({ commit }, id) {
    return new Promise((resolve, reject) => {
      verified(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  
  // SeekerEducation
  storeSeekerEducation({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeSeekerEducation(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSeekerEducation({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateSeekerEducation(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSeekerEducation({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteSeekerEducation(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // SeekerExperience
  storeSeekerExperience({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeSeekerExperience(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSeekerExperience({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateSeekerExperience(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSeekerExperience({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteSeekerExperience(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // SeekerCourse
  storeSeekerCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      storeSeekerCourse(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateSeekerCourse({ commit }, data) {
    return new Promise((resolve, reject) => {
      updateSeekerCourse(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSeekerCourse({ commit }, id) {
    return new Promise((resolve, reject) => {
      deleteSeekerCourse(id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  checkOperator({ commit }, data) {
    return new Promise((resolve, reject) => {
      checkOperator(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  notVerifiedProfiles({ commit }, data) {
    return new Promise((resolve, reject) => {
      notVerifiedProfiles(data)
      .then(res => {
          commit('SET_OPERATOR_MODEL', res['data']);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resumePdf({ commit }, id) {
    return new Promise((resolve, reject) => {
      resumePdf(id)
      .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  toBasket({ commit }, data) {
    return new Promise((resolve, reject) => {
      toBasket(data)
      .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  uploadAvatarBase64({ commit }, data) {
    return new Promise((resolve, reject) => {
      uploadAvatarBase64(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAvatarBase64({ commit }, data) {
    return new Promise((resolve, reject) => {
      getAvatarBase64(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  seekerProfilesDailyStatistics({ commit }, data) {
    return new Promise((resolve, reject) => {
      seekerProfilesDailyStatistics(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
