import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/";
import middlewares from "./middlewares";
import { checkTokenTime } from "@/utils/auth";

import companiesRouter from './modules/companies'
import vacanciesRouter from './modules/vacancies'
import profilesRouter from './modules/profiles'
import positionsRouter from './modules/positions'
import positionSynonymRouter from './modules/position-synonym'
import companyRequests from './modules/company-requests'
import statisticsRouter from './modules/statistics'

Vue.use(VueRouter)

var modules = [];
modules = modules.concat(companiesRouter);
modules = modules.concat(vacanciesRouter);
modules = modules.concat(profilesRouter);
modules = modules.concat(positionsRouter);
modules = modules.concat(positionSynonymRouter);
modules = modules.concat(companyRequests);
modules = modules.concat(statisticsRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    // redirect: { name: "Profiles" },
    component: () =>
      import("@/layouts/Main"),
    children: [{
      path: "/dashboard",
      name: "Dashboard",
      redirect: { name: "Profiles" },
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Dashboard/Index"),
      children: [{
        path: "companies",
        name: "Companies",
        meta: { middleware: 'Auth' },
        component: () =>
          import("@/views/Companies/Index"),
      },{
        path: "vacancies",
        name: "Vacancies",
        meta: { middleware: 'Auth' },
        component: () =>
          import("@/views/Vacancies/Index"),
      },{
        path: "profiles",
        name: "Profiles",
        meta: { middleware: 'Auth' },
        component: () =>
          import("@/views/Profiles/Index"),
      },{
        path: "responses",
        name: "Responses",
        meta: { middleware: 'Auth' },
        component: () =>
          import("@/views/Dashboard/Responses"),
      }]
    },{
      path: "profiles-by-position/:id",
      name: "ProfilesByPosition",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Profiles/Index"),
    },{
      path: "not-profession-profiles",
      name: "NotProfessionProfiles",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Profiles/NotProfessions"),
    },{
      path: "not-verified-otp",
      name: "NotVerifiedOtp",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Companies/NotVerifiedOtp"),
    },{
      path: "search-position",
      name: "SearchPosition",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/SearchPosition"),
    },{
      path: "positions",
      name: "Positions",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Positions/Index"),
    },{
      path: "skills",
      name: "Skills",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Skills/Index"),
    },{
      path: "position-synonym",
      name: "PositionSynonym",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/PositionSynonym/Index"),
    },{
      path: "utm-sourse-stat",
      name: "UtmSourseStat",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/UtmSourseStat/Index"),
    },{
      path: "utm-sourse-company",
      name: "UtmSourseCompany",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/UtmSourseCompany/Index"),
    },{
      path: "utm-sourses-tokens",
      name: "UtmSoursesTokens",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/UtmSourses/Index"),
    },{
      path: "otms",
      name: "Otms",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Otms/Index"),
    },{
      path: "training-centers",
      name: "TrainingCenters",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/TrainingCenters/Index"),
    },{
      path: "training-centers/create",
      name: "TrainingCentersCreate",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/TrainingCenters/Form"),
    },{
      path: "training-centers/edit/:id",
      name: "TrainingCentersEdit",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/TrainingCenters/Form"),
    },{
      path: "candidates-stat",
      name: "CandidatesStat",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Candidates/Index"),
    },{
      path: "profile-statistics",
      name: "ProfileStatistics",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/ProfileStatistics/Index"),
    },{
      path: "position-statistics",
      name: "PositionStatistics",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/PositionStatistics/Index"),
    }].concat(modules)
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import("@/views/Auth/Login"),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.middleware) {
    if (middlewares[to.meta.middleware]) {
      let $res = middlewares[to.meta.middleware](store);
      if (!$res || !checkTokenTime()) {
        return next({ name: "Login" });
      }
    }
  }
  return next();
});

export default router
