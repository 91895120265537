export const state = {
  list: [],
  pagination: null,
  model: null,
  recruiters: null,
  not_verified_otps: null,
  invoices: [],
  contracts: [],
  plans: [],
  customPlans: [],
  accounts: [],
}
