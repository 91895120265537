import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/moderator/positions',
    method: 'get',
    params
  });
}
export function show(id) {
  return request({
    url: '/moderator/positions/'+id,
    method: 'get',
  });
}
export function store(data) {
  return request({
    url: '/moderator/positions',
    method: 'post',
    data
  });
}
export function update(data) {
  return request({
    url: '/moderator/positions/'+data['id'],
    method: 'put',
    data
  });
}
export function parents() {
  return request({
    url: '/moderator/position-parents',
    method: 'get'
  });
}
export function addSkill(data) {
  return request({
    url: '/moderator/assign-skill-to-position',
    method: 'post',
    data
  });
}
export function removeSkill(data) {
  return request({
    url: '/moderator/remove-skill-from-position',
    method: 'delete',
    data
  });
}
export function addNewSkill(data) {
  return request({
    url: '/moderator/skills',
    method: 'post',
    data
  });
}
export function skillsList(params) {
  return request({
    url: '/moderator/skills',
    method: 'get',
    params
  });
}
