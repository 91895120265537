const routes = [
    {
      path: "company-requests/list",
      name: "CompanyRequests",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/CompanyRequests/Index"),
    },{
      path: 'company-requests/:id',
      name: 'CompanyRequestsShow',
      meta: {middleware: 'Auth'},
      component: () =>
          import("@/views/CompanyRequests/Show"),
    }
  ]
  
  export default routes;
  