const routes = [
  {
    path: "companies/list",
    name: "SellerCompanies",
    meta: { middleware: 'AuthSeller' },
    component: () =>
      import("@/views/Companies/Index"),
  },{
    path: "company-register",
    name: "CompanyCreate",
    meta: { middleware: 'AuthSeller' },
    component: () =>
      import("@/views/Companies/Create"),
  },{
    path: 'companies/edit/:id',
    name: 'CompaniesEdit',
    meta: {middleware: 'AuthSeller'},
    component: () =>
        import("@/views/Companies/Edit"),
  },{
    path: 'companies/:id',
    name: 'CompaniesShow',
    meta: {middleware: 'AuthSeller'},
    component: () =>
        import("@/views/Companies/Show"),
  }
]

export default routes;
