<template>
    <!-- <el-select v-bind:name="name" v-bind:id="idName" v-bind:class="className" v-on:change="onChangeCallback" v-model="fieldValue" v-bind:required="required">
        <el-option v-for="item in items" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select> -->
    <select v-bind:name="name" v-bind:id="idName" v-bind:class="className" v-on:change="onChangeCallback" v-model="fieldValue" v-bind:required="required">
        <option :value="item.value" v-for="item in items" :key="item.value">{{item.text}}</option>
    </select>
</template>

<script>
    export default {
        props:{
            name: String,
            idName: String,
            className: String,
            value: Number,
            values: Array,
            options: Array,
            onChange: {type: Function, default: null},
            required: {type: Boolean, default: false}
        },
        data () {
            return {
                items: [],
                fieldValue: this.value
            }
        },
        created (){
            this.processItems();
        },
        watch: { 
            values: function(newVal, oldVal) {
                this.processItems();
            },
            value: function(newVal, oldVal){
                this.fieldValue = this.value;
            }
        },
        methods: {
            processItems () {
                this.items = [];
                // console.log(this.values, this.options, this.name);
                for (let index = 0; index < this.values.length; index++) {
                    this.items.push({value: this.values[index], text: this.options[index]});
                }
            },
            onChangeCallback () {
                if(this.onChange != null){
                    this.onChange(this.fieldValue);
                }
            }
        }
    }
</script>
<style scoped>
select{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7' fill='none'%3E%3Cpath d='M10.6666 0.777819L5.9999 5.44449L1.33327 0.777819' stroke='%23A3A3A3' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    height: 50px;
    font-size: 16px;
    cursor: pointer;
}
</style>