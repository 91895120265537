import { mapGetters, mapActions } from 'vuex'
import {cloneObject as utilsCloneObject} from '@/utils/index'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      isAuth: 'auth/IS_AUTH',
      currentUser: 'auth/USER',
      loadRunning: 'loader/REQUEST_RUNNING',
      classifierTables: 'classifiers/TABLES',
      formOptions: 'classifiers/FORM_OPTIONS',
    }),
    role_name(){
      return this.currentUser?this.currentUser.role:'';
    }
  },
  methods: {
    ...mapActions({
      getClassifiersByTableName: 'classifiers/getClassifiers',
    }),
    cloneObject(object) {
      return utilsCloneObject(object);
    },
    changeQuery(key, value){
      let query = {...{}, ...this.$route.query};
      query[key] = value;
      this.$router.push({name: this.$route.name, params: this.$route.params, query });
    },
    getClassifier(table, param, fromDb = false){
      if (fromDb || !(this.classifierTables && this.classifierTables[table] && !param)){
        return this.getClassifiersByTableName({table: table, params: param});
      }
      return new Promise((resolve, reject) => {
        resolve(this.classifierTables[table])
      });
    },
    getYear(n){
      let str1 = 'год';
      let str2 = 'года';
      let str5 = 'лет';
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getAge(n){
      let str1 = 'год в';
      let str2 = 'года в';
      let str5 = 'лет в';
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getMonthRu(n){
      let str1 = 'месяц';
      let str2 = 'месяца';
      let str5 = 'месяцев';
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getNSTextRu(n, str1, str2, str5){
      return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
    },
    getTotal(data, field) {
      let total = 0;
      if (data && data.length) {
        data.forEach(item => {
          if (item[field]) {
            total += Number(item[field]);
          }
        });
      }
      return total;
    },
    openFile(base64, filename) {
      let link = document.createElement("a");
      link.href = base64;
      link.download = filename;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onlyLatters(string){
      if(string){
        return String(string).replace(/[^A-Za-zА-Яа-яЁё\s-]/g,'');
      }
      return string;
    }
  },
};
