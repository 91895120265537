<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  computed: {},
  watch: {},
  created() {
    this.getFormOptions()
  },
  methods: {
    ...mapActions({
      getFormOptions: 'classifiers/getFormOptions',
    }),
  }
};
</script>
<style lang="scss">
@import './styles.scss';
@import './assets/styles/media.scss';
</style>
