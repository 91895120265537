export const mutations = {
  SET_STAT: (state, data) => {
    state.stat = data;
  },
  SET_COMPANY: (state, data) => {
    state.company = data;
  },
  SET_LIST: (state, data) => {
    state.list = data;
  },
};